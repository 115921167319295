// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {imageUrlToBase64} from "../../../../utility/Utils";

export const setLoading = createAsyncThunk('SalesPackingSlipCustomization/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('SalesPackingSlipCustomization/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/InvoicePreviewCustomization/GetPackingListCustomization', { params })


    if(response.data.data.signatureImage) {
        try {
            response.data.data.signatureImageBase64 = await imageUrlToBase64(process.env.REACT_APP_API_URL_production + response.data.data.signatureImage);
        } catch(error) {
            console.log(error);
        }
    }

    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data,
    }
})

export const SaleInvoiceCustomizationSlice = createSlice({
    name: 'SalesPackingSlipCustomization',
    initialState: {
        data: {},
        bank_data: {},
        params: {},
        loadingState: { getData: false }
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default SaleInvoiceCustomizationSlice.reducer
